* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

/* Fix cron dropdown to show on top of mui modal */
.ant-select-dropdown {
    z-index: 1301;
}